
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Form, Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
// import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "admin-ocr-test",
  components: { Form, Field },
  setup() {
    // const { isEmptyArray } = ResusableFunctions();
    const submitButton1 = ref<HTMLElement | null>(null);
    const selectedFile = ref();
    const details = ref();

    const submit = () => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");
          const form = document.forms[0];
          const formData = new FormData(form);

          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(variables.OCR_ROUTE, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                details.value = response?.data?.data;
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageTitle("OCR Test");
    });

    return { submit, submitButton1, details, selectedFile };
  },
  created() {
    //Set page title
    document.title = "OCR Test | " + process.env.VUE_APP_TITLE;
  },
});
